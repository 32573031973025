<template>
  <div class="storeBox">
    <div class="card-box">
      <div class="card-hd">
        <div class="card-hd-left">
          SKU成本表列表
        </div>
        <div class="card-hd-right">
          <Refresh @refreshCall="getAllSkuCostList"></Refresh>
        </div>
      </div>
    </div>

    <div class="handleLineBox">
      <!-- select -->
      <div class="baseSelect">
        <!-- 模糊搜索 -->
        <SearchBox
          :searchVal="baseData.searchVal"
          placeholder="请输入Sku成本表名称"
          @searchCall="searchHandle"
        ></SearchBox>
        <!-- 下拉选择 -->
        <SelectDown
          :selectVal="baseData.selectVal"
          :selectData="baseData.selectData"
          @selectDownCall="selectDownHandle"
        ></SelectDown>
        <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('sku')">新增SKU成本表</el-button>
        <!-- 弹窗 Sku层 -->
        <div class="detailFormDialogBox">
          <el-dialog
              v-model="baseData.SimpleDialogShow"
              width="1140px"
              align-center
              :close-on-click-modal="false"
              @close="resetForm(ruleFormSkuCostRef)"
          >
            <div class="card-box">
              <div class="card-hd">
                <div class="card-hd-left">
                  {{ ruleFormSkuCost?.templateId ? '编辑' : '新增'}}SKU成本表
                </div>
                <div class="card-hd-right">
                </div>
              </div>
              <div class="card-bd">
                <div class="goodsForm">
                  <el-tabs tab-position="left"
                    v-model="tabsSku.currentIndex"
                    @tab-click="tabsListHandle">
                    <el-tab-pane
                      v-for="item in tabsSku.list"
                      :key="item.name"
                      :label="item.title"
                      :name="item.name">
                      <template #label>
                        {{ item.title }}
                      </template>
                    </el-tab-pane>
                  </el-tabs>
                  <div class="goodsFormBox">
                    <el-scrollbar
                      ref="goodsFormScoll"
                      :always="true">
                      <el-form
                        ref="ruleFormSkuCostRef"
                        :model="ruleFormSkuCost"
                        status-icon
                        :rules="rulesSkuCost"
                      >
                        <div class="card-box" id="cardItem0">
                          <div class="card-hd">
                            <div class="card-hd-left">
                              基础信息
                            </div>
                            <div class="card-hd-right">
                            </div>
                          </div>
                          <div class="card-bd">
                            <div class="card-bd-item is-alone">
                              <el-form-item label="SKU成本表名称" prop="templateName">
                                <el-input v-model="ruleFormSkuCost.templateName"
                                  :min="0"
                                  maxlength="30"
                                  placeholder="SKU成本表名称"
                                  show-word-limit
                                  clearable
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                            <el-button
                              type="primary"
                              icon="Upload"
                              @click="comHandleHandleUpload('sku')"
                            >上传SKU成本表</el-button>
                          </div>
                        </div>
                        <div class="card-box" id="cardItem1">
                          <div class="card-hd">
                          </div>
                          <div class="card-bd">
                            <div class="height-auto-table">
                              <el-table :data="ruleFormSkuCost.list" stripe style="width: 940px">
                                <!-- <el-table-column prop="id" label="skuId" width="120" /> -->
                                <el-table-column prop="SKU名称" label="SKU名称"/>
                                <el-table-column prop="SKU价格" label="SKU价格" width="120" />
                              </el-table>
                            </div>
                          </div>
                        </div>
                      </el-form>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer" v-if="ruleFormSkuCost?.templateId">
                <el-button type="primary" @click="submitFormSkuCost(ruleFormSkuCostRef, 'edit')">
                  保存
                </el-button>
              </span>
              <span class="dialog-footer" v-else>
                <el-button type="primary" @click="submitFormSkuCost(ruleFormSkuCostRef)">
                  保存
                </el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- table -->
    <div class="baseTable">
      <!-- <el-scrollbar> -->
      <el-table
        v-loading="baseData.tableLoading"
        :data="baseData.tableListData"
        style="width: 100%;">
        <el-table-column
          v-for="(item, index) in baseData.tableHdData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item?.minWidth"
          :fixed="item.prop == 'handle' ? 'right' : item.prop == 'name' ? 'left' : false"
          align="center"
        >
          <template #default="scope">
            <el-tag
              v-if="item.prop == 'platform'"
              type="danger"
              disable-transitions
            >
              {{ scope.row.platform }}
            </el-tag>
            <div v-else-if="item.prop == 'handle'">
              <el-button size="small" type="primary"
                @click="editSkuCostHandle(scope.row.id, scope.row.name)">
                编辑
              </el-button>
              <el-button size="small" type="danger" @click="delSkuCostHandle(scope.row.id)">
                删除
              </el-button>
            </div>
            <div v-else-if="item.prop == 'address'">
              {{ scope?.row[item.prop] ? scope.row[item.prop] : '未设置' }}
            </div>
            <span v-else>
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <!-- table empty -->
        <template #empty>
          <div class="baseTableEmpty">
            <el-empty description="暂无SKU成本表">
              <el-button type="primary" icon="CirclePlus" @click="SimpleDialogHandle('sku')">新增SKU成本表</el-button>
            </el-empty>
          </div>
        </template>
      </el-table>
      <!-- </el-scrollbar> -->
    </div>

    <!-- pagination 分页 -->
    <div class="basePagination">
      <el-config-provider :locale="zhCn">
        <el-pagination
          v-model:current-page="baseData.currentPage"
          v-model:page-size="baseData.pageSize"
          :page-sizes="baseData.pageSizes"
          layout="total, sizes, prev, pager, next, jumper"
          :total="baseData.pageTotal"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-config-provider>
    </div>

    <UploadExcel
      :onSuccess="excelCall"
      ref="UploadExcelCom"
      v-show="exlcelData?.header.length === 0"
    ></UploadExcel>

  </div>
</template>

<script setup>
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
// eslint-disable-next-line
import UploadExcel from '@/components/UploadExcel/index.vue'
import SelectDown from '@/components/SelectDown/index.vue'
import SearchBox from '@/components/SearchBox/index.vue'
import Refresh from '@/components/Refresh/index.vue'
import { onMounted, reactive, watch, ref, nextTick } from 'vue'
import {
  skuCostList,
  skuCostPageList,
  skuCostAdd,
  skuCostDel,
  skuCostDetail
} from '@/api/report.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { timeToDate, jsonToNewName } from '@/utils/unitTransform.js'
import { useRoute } from 'vue-router'

const route = useRoute()

const UploadExcelCom = ref(null)
const exlcelData = reactive({
  header: [],
  results: []
})
const baseData = reactive({
  selectTimes: '',
  selectVal: 'Option2',
  selectData: [
    {
      value: 'Option1',
      label: 'Option1'
    },
    {
      value: 'Option2',
      label: 'Option2'
    }
  ],
  tableLoading: false,
  tableHdData: [
    { label: 'SKU成本表名称', prop: 'name', minWidth: '200' },
    { label: '创建时间', prop: 'created_at', minWidth: '180' },
    { label: '操作', prop: 'handle', minWidth: '250' }
  ],
  tableListData: [],
  currentPage: 1,
  pageSize: 100,
  pageTotal: 0,
  pageSizes: [100, 200, 300, 400],
  SimpleDialogShow: false,
  searchVal: ''
})
// 基础组件 事务 start
const goodsFormScoll = ref()
// tabs信息
// tabs sku信息
const tabsSku = reactive({
  currentIndex: 0,
  list: [
    { title: '基础信息', name: 0 },
    { title: 'sku成本表预览', name: 1 }
  ]
})
// elementPlus滚动条缓动
const scrollToTopTransition = (willGo, nowSite = 0, duration = 300) => {
  let passedTime = 0
  const originTime = Date.now()
  // 贝塞尔缓动式由0到1
  const easeingFunction = (t) => {
    if (t < 0.5) {
      return (4 * t * t * t)
    } else {
      return ((t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
    }
  }
  const _scrollToTop = () => {
    if (passedTime < duration) {
      passedTime = Date.now() - originTime
      // duration(默认300毫秒)内重复执行
      requestAnimationFrame(_scrollToTop)
      // 当前位置nowSite到预期位置willGo的真实滚动距离
      const trueDistance = (nowSite + (willGo - nowSite) * (easeingFunction(passedTime / duration)))
      goodsFormScoll.value.setScrollTop(trueDistance)
    }
  }
  _scrollToTop()
}
// tabs点击事件
const tabsListHandle = (e) => {
  const indexNum = Number(e.index)
  const willGoCard = document.querySelector(`#cardItem${indexNum}`)
  willGoCard.classList.add('willFocus')
  setTimeout(() => {
    willGoCard.classList.remove('willFocus')
  }, 2000)
  scrollToTopTransition(tabsSku.list[indexNum].offsetTop - 12, baseData.scrollSite)
}
// 获取cradItem锚点距顶坐标
const getCardItemSite = () => {
  nextTick(() => {
    const list = tabsSku.list
    for (const i in list) {
      tabsSku.list[i].offsetTop = document.querySelector(`#cardItem${i}`).offsetTop
    }
  })
}

// 搜索事件
const searchHandle = (type, searchBox) => {
  baseData.searchVal = type ? searchBox.searchVal : ''
  getSkuCostPageList()
}

// tabs currentIndex监听
watch(() => tabsSku.currentIndex, (newValue) => {
  console.log('watch 已触发', newValue)
})
watch(() => baseData.SimpleDialogShow, (newValue) => {
  newValue && getCardItemSite()
})

// Sku成本表表单 action
// 表单 信息
const ruleFormSkuCost = reactive({
  templateName: '', // 模板名称
  list: []
})
// 表单 验证相关
const ruleFormSkuCostRef = ref()
// 表单 模板名校验规则
const checkSkuCostName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入模板名称'))
  } else {
    callback()
  }
}
// 表单 校验规则
const rulesSkuCost = reactive({
  templateName: [{ required: true, validator: checkSkuCostName, trigger: 'blur' }]
})
// 表单 验证后提交表单
const submitFormSkuCost = (formEl, isEdit) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('提交!', ruleFormSkuCost)
      addSkuCostHandle(ruleFormSkuCost)
      if (!isEdit) {
        addSkuCostHandle(ruleFormSkuCost)
      } else {
        ElMessage.error('sku成本表编辑功能制作中 Comedy')
      }
    } else {
      console.log('不能提交')
      return false
    }
  })
}
// 表单 重置表单
const resetForm = (formEl, typeName) => {
  if (!formEl) return
  if (typeName) {
    ElMessageBox.confirm(
      `${typeName}后所有未保存的内容均会丢失，是否继续？`,
      {
        confirmButtonText: '继续',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      formEl.resetFields()
    }).catch(() => {
    })
  } else {
    formEl.resetFields()
    clearEditObject()
  }
}

const clearEditObject = () => {
  ruleFormSkuCost.templateName = null
  ruleFormSkuCost.templateId = null
  ruleFormSkuCost.list = []
}
// Sku成本表表单 end
// 基础组件 事务 end

// 分页相关
const handleSizeChange = (val) => {
  console.log(`${val} items per page`)
}
const handleCurrentChange = (val) => {
  console.log(`current page: ${val}`)
}
// 下拉选择相关
const selectDownHandle = (val) => {
  baseData.selectVal = val
}
// simpleDialog 弹窗唤醒事件
const SimpleDialogHandle = (type) => {
  baseData.SimpleDialogShow = true
}

// 获取SKU成本列表
const getSkuCostList = async () => {
  const res = await skuCostList({
    template_name: baseData.searchVal
  })
  console.log('getSkuCostList', res)
}
// 获取SKU成本分页列表
const getSkuCostPageList = async () => {
  const resData = await skuCostPageList({
    template_name: baseData.searchVal,
    page: baseData.currentPage,
    page_size: baseData.pageSize
  })
  for (const i in resData.data) {
    const dataItem = resData.data[i]
    if (dataItem?.created_at) {
      dataItem.created_at = timeToDate(dataItem?.created_at)
    }
  }
  baseData.tableListData = resData.data
  baseData.pageTotal = resData.total
  console.log('getSkuCostPageList', resData)
}
// 新增SKU成本表接口
const addSkuCostHandle = async (ruleForm) => {
  for (const i in ruleFormSkuCost.list) {
    const skuCostItem = ruleFormSkuCost.list[i]
    jsonToNewName(skuCostItem, 'SKU名称', 'sku_name')
    jsonToNewName(skuCostItem, 'SKU价格', 'cost_price')
  }
  await skuCostAdd({
    template_name: ruleForm.templateName,
    content: JSON.stringify(ruleFormSkuCost.list)
  })
  getSkuCostPageList()
  baseData.SimpleDialogShow = false
}
// 编辑模板接口
const editSkuCostHandle = async (id, skuCostName) => {
  console.log('editTemplateHandle 编辑模板接口 Comedy', ruleFormSkuCost, skuCostName)
  const resData = await skuCostDetail({
    template_id: id
  })
  ruleFormSkuCost.templateId = id
  ruleFormSkuCost.templateName = skuCostName
  for (const i in resData) {
    const skuCostItem = resData[i]
    jsonToNewName(skuCostItem, 'sku_name', 'SKU名称')
    jsonToNewName(skuCostItem, 'cost_price', 'SKU价格')
  }
  ruleFormSkuCost.list = resData
  baseData.SimpleDialogShow = true
}
// 删除模板事件 — 询问
const delSkuCostHandle = (id) => {
  ElMessageBox.confirm(
    '删除后该SKU成本表将不可再次使用，是否继续？',
    {
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }
  ).then(() => {
    delApiHandle(id)
  }).catch(() => {
  })
}
// 删除模板事件 — 接口
const delApiHandle = async (id) => {
  await skuCostDel({
    ids: [id]
  })
  getSkuCostPageList()
}

const getAllSkuCostList = () => {
  getSkuCostList()
  getSkuCostPageList()
}

onMounted(() => {
  getAllSkuCostList()
})

watch(() => route.params, (newParams) => {
  console.log('watch 监听路由 sku', newParams?.event)
  if (newParams.event === 'addSkucost') SimpleDialogHandle('sku')
}, {
  immediate: true
})

// 点击调用子组件的上传事件
const comHandleHandleUpload = (scene) => {
  UploadExcelCom.value && UploadExcelCom.value.handleUpload('', scene)
}
// excel组件回调
const excelCall = (resData) => {
  console.log('excel组件回调', resData)
  // 如果是sku成本表上传，则要遵循sku成本表规范
  const callRule = ['SKU名称', 'SKU价格']
  if (resData.header.length !== 2) {
    ElMessage.error('sku成本表表头必须为"SKU名称","SKU价格"')
  } else {
    for (const i in resData.header) {
      if (callRule.indexOf(resData.header[i]) === -1) {
        ElMessage.error('sku成本表表头必须为"SKU名称","SKU价格"')
        return
      }
    }
    ruleFormSkuCost.list = resData.results
    ElMessage.success('sku成本表上传成功"')
  }
}
</script>

<style lang="scss" >
@import '~@/styles/variables.scss';
@import '~@/styles/mixin.scss';
@import '~@/styles/report/index.scss';
</style>
